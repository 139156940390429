import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Prevent duplicate submissions and show "loading" state.
Array.from(document.querySelectorAll('form')).forEach((form) => {
  const formButton = form.querySelector('button[type="submit"]');
  if (!formButton) {
    return;
  }
  form.addEventListener('submit', () => {
    formButton.setAttribute('disabled', true);
    formButton.classList.add('is-loading');
  });
});
